import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'layouts/MainLayout';
import { DarkScheme } from 'helpers/navColors';
import { Albums, Banner, Features, SEO } from 'components';
import { InnerWrap, HeaderWrap } from 'components/common/Containers/styled';

import { Content } from './styled';

const ArtistTemplate = ({ data }) => {
  const { strapiArtists, allStrapiFeatures } = data;
  const {
    englishName,
    japaneseName,
    bio,
    spotify,
    appleMusic,
    albums,
    bannerBackgroundColor,
    bannerTextColor,
  } = strapiArtists;

  const bannerConfig = {
    bannerBackgroundColor: bannerBackgroundColor,
    bannerTextColor: bannerTextColor,
    title: englishName,
    bio: bio,
    spotify: spotify,
    appleMusic: appleMusic,
    type: 'person',
  };

  return (
    <MainLayout
      navColor={DarkScheme}
      headerColor={bannerTextColor ? bannerTextColor : 'black'}
    >
      <SEO
        title={`${englishName} - Features and reccs`}
        description={`${englishName} articles`}
      />

      <Banner {...bannerConfig} />

      <Content>
        <InnerWrap>
          {albums.length > 0 && (
            <HeaderWrap>
              <h3>Our reccs</h3>
            </HeaderWrap>
          )}
          {albums.length > 0 && <Albums edges={Object.values(albums)} />}

          {allStrapiFeatures.edges.length > 0 && (
            <HeaderWrap>
              <h3>Features</h3>
            </HeaderWrap>
          )}
          {allStrapiFeatures.edges.length > 0 && (
            <Features {...allStrapiFeatures} />
          )}
        </InnerWrap>
      </Content>
    </MainLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    strapiArtists(slug: { eq: $slug }) {
      englishName
      japaneseName
      bio
      slug
      spotify
      appleMusic
      bannerBackgroundColor
      bannerTextColor
      albums {
        englishTitle
        slug
        releaseDate(formatString: "MMMM D YYYY")
        artwork {
          url
        }
      }
    }
    allStrapiFeatures(
      filter: { artists: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
  }
`;

export default ArtistTemplate;
